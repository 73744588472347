import { ApiError } from '@mechhive/api';
import { getClientIpAddress, notFound, toManyRequests } from '@mechhive/remix';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import type { ShouldRevalidateFunction } from 'react-router';
import {
  Outlet,
  isRouteErrorResponse,
  redirectDocument,
  useRouteError
} from 'react-router';
import { match } from 'ts-pattern';
import { createRewarbleApi } from '~/api/rewarble.api.server';
import type { RewarbleAccount } from '~/api/types/rewarble/RewarbleAccount';
import { Button } from '~/components/Button';
import { useLanguageServer } from '~/hooks/useLanguage.server';
import { useLocalePathServer } from '~/hooks/useLocalePath.server';
import { useRegionServer } from '~/hooks/useRegion.server';
import { useTranslate } from '~/hooks/useTranslate';
import { SUPPORTED_LANGUAGES } from '~/locale';
import { commitSession, getSession } from '~/session.server';
import type { Route } from './+types/($lang)';

export const loader = async ( { request, params }: Route.LoaderArgs ) => {
  const language = useLanguageServer( params );
  const localePath = useLocalePathServer( params );
  if ( !SUPPORTED_LANGUAGES.includes( language as any ) ) {
    throw notFound();
  }
  
  const session = await getSession( request.headers.get( 'Cookie' ) );
  const authWrapper = session.get( 'authWrapper' );
  
  let account : RewarbleAccount | undefined = undefined;

  try {
    const rewarbleApi = createRewarbleApi( request );
    account = authWrapper?.userAccount ? ( await rewarbleApi.web.account.get() ).data : undefined;

    if ( account?.authWrapper ) {
      session.set( 'authWrapper', account.authWrapper );
      
      if ( account.requireManualReview === true ) {
        const url = new URL( request.url );
        if ( !url.pathname.includes( '/security/manual-review' ) ) {
          return redirectDocument( localePath( '/security/manual-review' ), {
            headers: {
              'Set-Cookie': await commitSession( session )
            }
          } );
        }
      }
    }

  } catch ( ex ) {
    if ( ex instanceof ApiError ){
      if ( ex.response.status === 429 ) {
        throw toManyRequests();
      }

      if ( ex.response.status === 401 ) {
        const auth = session.get( 'authWrapper' );
        if ( auth != null ) {
          session.set( 'authWrapper', undefined );
        }

        if ( auth != null ) {
          return redirectDocument( request.url, {
            headers: {
              'Set-Cookie': await commitSession( session )
            }
          } ) 
        }
      }
    }
  }

  const region = await useRegionServer( request );
  const ip = getClientIpAddress( request ) ?? '127.0.0.1';
  return {
    ip,
    authWrapper: {
      ...authWrapper,
      token: undefined
    },
    account,
    region,
    language,
    hasKycCompleted: account?.hasKycCompleted ?? false
  };
}

export const shouldRevalidate : ShouldRevalidateFunction = ( { defaultShouldRevalidate, currentUrl, nextUrl } ) => {
  if ( currentUrl.pathname === nextUrl.pathname ) {
    return false;
  }

  return defaultShouldRevalidate;
}


export type LangLayoutLoader = typeof loader;

export function ErrorBoundary() {
  const error = useRouteError();

  if ( isRouteErrorResponse( error ) ) {
    const t = useTranslate();

    return (
      <div className={ 'container mx-auto px-4 flex flex-col gap-24 lg:gap-[100px] items-center' }>
        <div className={ 'pt-[80px] flex flex-col items-center gap-[50px]' }>
          <div className={ 'flex flex-col items-center gap-2.5' }>
            <div className={ 'text-8xl font-semibold' }>{ error.status }</div>
            <div className={ 'text-4xl font-semibold' }>
              { 
                match( error.status )
                  .with( 400, () => 'Bad Request' )
                  .with( 401, () => 'Unauthorized' )
                  .with( 403, () => 'Forbidden' )
                  .with( 404, () => 'Not Found' )
                  .with( 410, () => 'Gone' )
                  .with( 429, () => 'To Many Requests' )
                  .with( 500, () => 'Internal Server Error' )
                  .otherwise( () => 'Unknown Error' )
              }
            </div>
          </div>
          <div className={ 'flex flex-col gap-[30px]' }>
            <div className={ 'text-center text-pretty' }>
              { 
                match( error.status )
                  .with( 400, () => t( 'ERROR_400_SUBTITLE' ) )
                  .with( 401, () => t( 'ERROR_401_SUBTITLE' ) )
                  .with( 403, () => t( 'ERROR_403_SUBTITLE' ) )
                  .with( 404, () => t( 'ERROR_404_SUBTITLE' ) )
                  .with( 410, () => t( 'ERROR_410_SUBTITLE' ) )
                  .with( 429, () => t( 'ERROR_429_SUBTITLE' ) )
                  .otherwise( () => t( 'ERROR_500_SUBTITLE' ) )
              }
            </div>
            <div className={ 'flex flex-row justify-center gap-4' }>
              <Button
                to={ '/' }
                reloadDocument={ true }
                variant={ error.status === 401 ? 'secondary' : 'primary' }
              >
                <span className={ 'px-4' }>
                  {t( 'ERROR_BACK_BUTTON' )}
                </span>
              </Button>
              { error.status === 401 &&
                <Button
                  to={ '/signin' }
                  reloadDocument={ true }
                >
                  <span className={ 'px-4' }>
                    {t( 'HEADER_MENU_SIGNIN' )}
                  </span>
                </Button>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={ 'container mx-auto px-4 flex flex-col gap-24 lg:gap-[100px] items-center' }>
      <div className={ 'pt-[80px] flex flex-col items-center gap-[50px]' }>
        <div className={ 'flex flex-col items-center gap-2.5' }>
          <div className={ 'text-8xl font-semibold' }>{ 500 }</div>
        </div>
        <div className={ 'flex flex-col gap-[30px]' }>
          <div>
            { 'Internal Server Error' }
          </div>
          <div className={ 'flex flex-row justify-center' }>
            <Button
              to={ '/' }>
              Go to Homepage
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function Lang( { loaderData }: Route.ComponentProps ) {

  useEffect( () => {
    if ( loaderData.authWrapper ) {
      Sentry.setUser( {
        email: loaderData?.authWrapper.userAccount?.email,
        ip_address: loaderData.ip,
        currency: loaderData?.authWrapper?.userAccount?.currency,
        region: loaderData.region ?? 'no-region',
        language: loaderData.language ?? 'no-language'
      } )
    } else {
      Sentry.setUser( {
        email: 'Anonymous',
        ip_address: loaderData.ip,
        region: loaderData.region ?? 'no-region',
        language: loaderData.language ?? 'no-language'
      } );
    }
  }, [ loaderData ] );

  return(
    <Outlet />
  )
}
